import { Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from '../../ui-library/components/buttons/action-button';
import { Topbar } from '../../ui-library/components/navs/topbar';
import { PAGE_NOT_FOUND, SYNICATE_LOGO_COLOUR } from '../../common/Images';
import useStyles from './styles';

const PageNotFound = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <div className={classes.mainBody}>
        <Topbar position="relative">
          <img src={SYNICATE_LOGO_COLOUR} alt="trica" style={{ width: '200px', height: 'auto' }} />
        </Topbar>
        <div className={classes.container}>
          <Grid container>
            <Grid xs={12} sm={4} className={classes.gridContainer}>
              <Typography className={classes.description}>Page not found...</Typography>
              <Typography className={classes.subDescription}>
                Sorry, the page you are looking for doesn&apos;t exist
              </Typography>
              <ActionButton
                onClick={() => {
                  navigate('/');
                }}
              >
                BACK TO DASHBOARD
              </ActionButton>
            </Grid>
            <Grid xs={12} sm={8} className={classes.gridContainer}>
              <img src={PAGE_NOT_FOUND} alt="page not found" />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
