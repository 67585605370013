import requestManager from '../../../api/requestManager';

const getAllBackers = (syndicateId: string) =>
  requestManager.apiGet(`/v1/syndicates/syndicate_people_info/?id=${syndicateId}`);

const getBackers = (syndicatePermalink: string) =>
  requestManager.apiGet(`/v1/syndicates/get_backers/?permalink=${syndicatePermalink}`);

const getBackerDetail = (syndicateId: string, backerId?: string) =>
  requestManager.apiGet(`/v1/syndicates/get_non_zeno_backer_details/?id=${syndicateId}&inv_id=${backerId}`);

const inviteMemeber = (syndicateId: string, memberType: string, actionType: string, data: any) =>
  requestManager.apiPost(
    `/v1/syndicates/invite_member/?type_of_member=${memberType}&type_of_action=${actionType}&id=${syndicateId}`,
    data
  );
const reInviteMember = (permalink: string, data: any) =>
  requestManager.apiPost(
    `/v1/syndicates/syndicate_communication_request/?permalink=${permalink}&source=glassboard`,
    data
  );

const bulkInviteMember = (syndicateId: string, data: any) =>
  requestManager.apiPost(`/v1/syndicates/bulk_invite_members/?id=${syndicateId}`, data, {
    'Content-Type': 'multipart/form-data'
  });

const bulkReInviteMember = (permalink: string, data: any) =>
  requestManager.apiPost(
    `/v1/syndicates/bulk_syndicate_communication_request/?permalink=${permalink}&source=glassboard`,
    data
  );

const checkCommunicationRecord = (permalink: string, inv_id: any, type_of_comm: string) =>
  requestManager.apiGet(
    `/v1/syndicates/check_syndicate_communication_record/?permalink=${permalink}&startup_id=&inv_id=${inv_id}&type_of_comm=${type_of_comm}`
  );

const sendTestEmailWhatsapp = (syndicateId: string, typeOfAction: string, data: any) =>
  requestManager.apiPost(`/v1/syndicates/send_test_email/?id=${syndicateId}&type_of_action=${typeOfAction}`, data);

export default {
  getAllBackers,
  getBackerDetail,
  inviteMemeber,
  reInviteMember,
  bulkInviteMember,
  bulkReInviteMember,
  checkCommunicationRecord,
  getBackers,
  sendTestEmailWhatsapp
};
