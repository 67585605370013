import { Box, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { DropdownButton } from '../../../ui-library/components/buttons/dropdown-button';
import { ActionOptions, ActionOptionsList } from '../constants/IndividualDealConstant';
import DraftDeal from './DraftDeal';
import ShareDealModal from '../modals/ShareDealModal';
import { AuthContext } from '../../../contexts/authContext';
import DealsManager from '../manager/DealsManager';
import { toast } from 'react-toastify';
import StartupResponse from './StartupResponse';
import { API_STATUS } from '../../../constants/commonConstants';
import LiveDealSkeleton from '../skeletons/LiveDealSkeleton';

const IndividualDeal = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const dealsManager = new DealsManager(authContext);
  const { id } = useParams();

  const [dropDownOption, setDropdownOption] = useState(ActionOptionsList);
  const [showShareDealModal, setSSDM] = useState(false);

  const [schemeData, setSchemeData] = useState<any>(null);
  const [startupData, setStartupData] = useState<any>(null);
  const [backersForDeal, setBackersForDeal] = useState<any>([]);
  const [syncAdminsForDeal, setSyncAdminForDeal] = useState<any>([]);
  const [communicationLogs, setCommunicationLogs] = useState<any>([]);

  const [apiStatusDealData, setApiStatusDealData] = useState(API_STATUS.LOADING);
  const [apiStatusBackerData, setApiStatusBackerData] = useState(API_STATUS.LOADING);

  const getDealData = async () => {
    try {
      const result = await dealsManager.getDealDetail(id);
      if (result?.scheme_data) {
        setSchemeData(result?.scheme_data);
      }
      if (result?.startups_response) {
        setStartupData(result?.startups_response);
        const updatedDropdownOption = ActionOptionsList?.map((item: any) => {
          item.disabled = !result?.startups_response?.publish_for_syn && item?.label === ActionOptions.SHARE_DEAL_URL;
          return item;
        });
        setDropdownOption(updatedDropdownOption);
      }
      setApiStatusDealData(API_STATUS.SUCCESS);
      if (result?.startups_response?.syndicate_deal_id) {
        await getBackersOfDealData(result?.startups_response?.syndicate_deal_id);
      }
    } catch (error: any) {
      toast.error(error);
      setApiStatusDealData(API_STATUS.SUCCESS);
    }
  };

  const getBackersOfDealData = async (syndicate_deal_id: string) => {
    try {
      const result = await dealsManager.getBackersForDeal(syndicate_deal_id);
      if (result) {
        setBackersForDeal(result?.all_backers_info?.filter((item: any) => !!item?.backer_status));
        setSyncAdminForDeal(result?.syn_admins);
        setCommunicationLogs(result?.communication_arr ?? []);
      }
      setApiStatusBackerData(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error);
      setApiStatusBackerData(API_STATUS.SUCCESS);
    }
  };

  useEffect(() => {
    getDealData();
  }, []);

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      },
      {
        title: startupData?.name,
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: startupData?.name,
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const openModal = () => setSSDM(startupData?.deal_url);
  const closeModal = () => setSSDM(false);

  const handleActionClick = (value: string) => {
    switch (value) {
      case ActionOptions.SHARE_DEAL_URL:
        openModal();
        return;
      case ActionOptions.VIEW_STARTUP_DETAILS:
        navigate(`/deals/edit/${id}`, { state: { viewMode: true } });
        return;
      case 'default':
        return;
    }
  };

  return (
    <Stack sx={{ overflowX: 'hidden' }} gap={2}>
      {showShareDealModal && <ShareDealModal openModal={showShareDealModal} handleClose={closeModal} />}
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <Box gap={1} display="flex">
          <DropdownButton options={dropDownOption} onOptionClick={handleActionClick} buttonText="Actions" />
        </Box>
      </Stack>
      {apiStatusDealData === API_STATUS.LOADING ? (
        <LiveDealSkeleton />
      ) : (
        <>
          {startupData?.publish_for_syn && startupData?.publish ? (
            <StartupResponse
              startupData={startupData}
              backersForDeal={backersForDeal}
              syncAdminsForDeal={syncAdminsForDeal}
              apiStatusBackerData={apiStatusBackerData}
              communicationLogs={communicationLogs}
            />
          ) : (
            <DraftDeal schemeData={schemeData} startupData={startupData} />
          )}
        </>
      )}
    </Stack>
  );
};

export default IndividualDeal;
