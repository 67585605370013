import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import FilterChip from '../../../ui-library/components/filter-chip/FilterChip';
import { CheckCircleOutline } from '@mui/icons-material';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { DealStatusEnum, DealStatusEnumLabel } from '../constants/DealsConstant';
import TableLogo from '../../common/components/TableLogo';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/authContext';
import DealsManager from '../manager/DealsManager';
import { toast } from 'react-toastify';
import { validateArray } from '../../../common/utils/common';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import { API_STATUS } from '../../../constants/commonConstants';
import { TablePlaceholder } from '../../../ui-library/components/placeholders/table-placeholder/TablePlaceholder';
import { NoDataAvailable } from '../../../ui-library/components/error/NoDataAvailable';
import { IconConstant } from '../../../constants/iconConstants';

const Deals = () => {
  const authContext = useContext(AuthContext);
  const backersManager = new DealsManager(authContext);
  const navigate = useNavigate();

  const [data, setData] = useState<any>(null);
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);

  const getDealsData = async () => {
    try {
      const result = await backersManager.getAllDeals();
      if (result?.all_count > 0) {
        setData(result);
      }
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getDealsData();
  }, []);

  const [filters, setFilters] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState<string>(DealStatusEnum.ALL);

  const navigateToLaunch = () => {
    navigate('/deals/create');
  };

  const breadcrumbData = {
    breadcrumbSeparator: false,
    stepKey: 1,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Deals',
      navigationButton: false,
      helpText: 'Deals'
    }
  };

  const columnsData = [
    {
      field: 'logo',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 50,
      sortable: false,

      renderCell: (params: any) => <TableLogo id={params?.row?.id} url={params?.row?.logo} />
    },
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'STARTUP NAME',
      isSearchable: true,
      renderCell: (params: any) => (
        <LinkButton
          title={params?.row?.name}
          onClick={() => navigate(`/deals/${params?.row?.id}`, { state: params?.row })}
        />
      )
    },
    {
      field: 'deal_status',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'DEAL STATUS',
      renderCell: (params: any) => DealStatusEnumLabel[params?.row?.deal_status]
    },
    {
      field: 'launched_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'LAUNCHED DATE',
      renderCell: (params: any) => params?.row?.formated_launched_date
    },
    {
      field: 'lv_ask',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'FUNDING ASK',
      type: 'number',
      renderCell: (params: any) => (params?.row?.lv_ask ? params?.row?.formated_lv_ask : '')
    },
    {
      field: 'total_commitment_diy',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'COMMITED',
      type: 'number',
      renderCell: (params: any) =>
        params?.row?.total_commitment_diy ? <>{params?.row?.formated_total_commitment_diy}</> : ' '
    },
    {
      field: 'lv_total_received',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 200,
      type: 'number',
      headerName: 'TRANSFER TO STARTUP',
      renderCell: (params: any) =>
        params?.row?.lv_total_received ? (
          <>
            {params?.row?.formated_lv_total_received}({params?.row?.total_received_count})
          </>
        ) : (
          ''
        )
    },
    {
      field: 'transfer_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'date',
      headerName: 'TRANSFER DATE',
      renderCell: (params: any) => (params?.row?.transfer_date ? params?.row?.formated_transfer_date : '')
    }
  ];

  const filterDataList = (fltr: string) => {
    switch (fltr) {
      case DealStatusEnum.ALL:
        return data?.all_startups ?? [];
      case DealStatusEnum.ONGOING:
        return data?.ongoing_startups ?? [];
      case DealStatusEnum.SAVED:
        return data?.saved_startups ?? [];
      case DealStatusEnum.FUNDED:
        return data?.funded_startups ?? [];
      case DealStatusEnum.CANCELLED:
        return data?.canceled_startups ?? [];
      case DealStatusEnum.CLOSED:
        return data?.closed_startups ?? [];
      default:
        return data?.all_startups ?? [];
    }
  };

  if (apiStatus === API_STATUS.ERROR) {
    return <NoDataAvailable title="Error" subTitle={'Something went wrong'} image={IconConstant.DATA_NOT_FOUND_ICON} />;
  }

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
        <Box gap={1} display="flex">
          <ActionButton onClick={navigateToLaunch}>Launch New Deal</ActionButton>
        </Box>
      </Stack>
      <Stack direction="row" gap={2} width="fit-content" alignItems="center" mb={2} mt={1}>
        <Typography fontSize="14px">Filter By Status:</Typography>
        <FilterChip
          label={DealStatusEnumLabel[DealStatusEnum.ALL]}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.ALL}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.ALL);
          }}
        />
        <FilterChip
          label={DealStatusEnumLabel[DealStatusEnum.ONGOING]}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.ONGOING}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.ONGOING);
          }}
        />
        <FilterChip
          label={DealStatusEnumLabel[DealStatusEnum.SAVED]}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.SAVED}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.SAVED);
          }}
        />
        <FilterChip
          label={DealStatusEnumLabel[DealStatusEnum.FUNDED]}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.FUNDED}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.FUNDED);
          }}
        />
        <FilterChip
          label={DealStatusEnumLabel[DealStatusEnum.CLOSED]}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.CLOSED}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.CLOSED);
          }}
        />
        <FilterChip
          label={DealStatusEnumLabel[DealStatusEnum.CANCELLED]}
          icon={<CheckCircleOutline fontSize="small" />}
          active={selectedStatus === DealStatusEnum.CANCELLED}
          onClick={() => {
            setSelectedStatus(DealStatusEnum.CANCELLED);
          }}
        />
      </Stack>
      {apiStatus === API_STATUS.LOADING ? (
        <TablePlaceholder rows={6} columns={6} rowHeight="40px" columnHeaderHeight="40px" />
      ) : (
        <LvComplexTable
          rows={filterDataList(selectedStatus)}
          columns={columnsData}
          leftPinnedColumns={['logo', 'name']}
          activeFilters={filters}
          onFilterChange={(filters: any) => setFilters(filters)}
          rowHeight={48}
          headerHeight={50}
        />
      )}
    </Box>
  );
};

export default Deals;
