import React, { FC, useContext, useState } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { FormContainer } from '../../../ui-library/components/form-elements/FormContainer';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Stack } from '@mui/material';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { InputField } from '../../../ui-library/components/form-elements/CustomTextField';
import SettingManager from '../manager/SettingManager';
import { AuthContext } from '../../../contexts/authContext';
import * as Yup from 'yup';
import { API_STATUS } from '../../../constants/commonConstants';

const AddAdmin: FC<any> = ({ openModal, handleClose, getAdminData }) => {
  const authContext = useContext(AuthContext);
  const settingManager = new SettingManager(authContext);
  const initialValues = { name: '', email: '', inviteMsg: '' };

  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    inviteMsg: Yup.string().required('Required')
  });

  const handleSubmit = async (values: any) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      const response = await settingManager.inviteAdminMember(values);
      if (response?.success) {
        toast.success(response?.message);
        setApiStatus(API_STATUS.SUCCESS);
        getAdminData && getAdminData();
        handleClose();
      }
    } catch (e: any) {
      toast.error(e);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  return (
    <CommonModal isOpen={openModal} handleClose={handleClose} title={'Add Admin'} hideButtons>
      <FormContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {(form) => (
            <Stack gap={2}>
              <InputField
                value={form?.values?.name}
                fieldName="name"
                id="name"
                name="name"
                label="Name"
                required
                error={!!form.errors.name}
              />
              <InputField
                value={form?.values?.email}
                fieldName="email"
                id="email"
                name="email"
                label="Email"
                required
                error={!!form.errors.email}
              />
              <InputField
                value={form?.values?.inviteMsg}
                fieldName="inviteMsg"
                id="inviteMsg"
                name="inviteMsg"
                label="Invitation Message"
                required
                error={!!form.errors.inviteMsg}
                multiline
                minRows={5}
              />
              <br />
              <Stack direction="row" justifyContent="end">
                <ActionButton
                  onClick={form.handleSubmit}
                  isLoading={apiStatus === API_STATUS.LOADING}
                  disabled={apiStatus === API_STATUS.LOADING || apiStatus === API_STATUS.ERROR}
                >
                  Invite as Admin
                </ActionButton>
              </Stack>
            </Stack>
          )}
        </Formik>
      </FormContainer>
    </CommonModal>
  );
};

export default AddAdmin;
