import React, { useCallback, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  DataGridPro,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridFilterItem,
  GridLinkOperator,
  gridPageCountSelector,
  gridPageSelector,
  GridRowModel,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './lv-complex-table-styles';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import { getColor } from '../../colors';
import { dateFilterOperators, selectFilterOperators } from './custom-filter-operators';
import { ActiveFiltersPanel, CustomGridFilterItem } from './active-filters-panel';
import { CheckboxDropdown } from '../dropdowns/checkbox-dropdown';
import { CustomDatePicker } from '../form-elements/DatePicker';
import { DateFilterDropdown } from '../dropdowns/date-filter-dropdown';
import { NumberFilterDropdown } from '../dropdowns/number-filter-dropdown';
import { TextFilterDropdown } from '../dropdowns/text-filter-dropdown';

const SearchBar = (props: any) => {
  const {
    onChange,
    onKeyDown,
    type = 'string',
    advancedFiltering,
    advanceNumberFiltering,
    field = '',
    columnName = '',
    selectData = [],
    advancedFilters,
    filterObject,
    openOnRight = false,
    showOnlyContains = false
  }: any = props;
  const classes = useStyles();
  const [textFilterAnchorEl, setTextFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [dateFilterAnchorEl, setDateFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [selectAnchorEl, setSelectAnchorEl] = useState<null | HTMLElement>(null);
  const [checkBoxData, setCheckBoxData] = useState(selectData);
  const openTextFilter =
    Boolean(textFilterAnchorEl) && ((advanceNumberFiltering && type === 'number') || advancedFiltering);
  const openDateFilter = Boolean(dateFilterAnchorEl) && advancedFiltering;
  const openSelect = Boolean(selectAnchorEl) && advancedFiltering;
  const searchTextFieldRef = useRef(null);
  const selectDropdownRef = useRef(null);
  const selectDateRef = useRef(null);

  const handleSearchClick = () => {
    setTextFilterAnchorEl(searchTextFieldRef?.current);
  };

  const handleDateClick = () => {
    setDateFilterAnchorEl(selectDateRef?.current);
  };

  const handleSelectClick = () => {
    setSelectAnchorEl(selectDropdownRef?.current);
  };

  if (type === 'date') {
    return (
      <div className={classes.searchBoxWrapper}>
        <div
          onClick={() => handleDateClick()}
          className={classes.searchBox}
          style={{ height: '37px !important' }}
          ref={selectDateRef}
        >
          <CustomDatePicker
            alignCalenderIcon={'left'}
            avoidDatePicker={advancedFiltering}
            defaultCursor
            value={filterObject?.value?.length ? filterObject?.value : null}
            onChange={(value: any) => onChange(value)}
            height={37}
            showLabel={false}
            openAdvancedFilter={handleDateClick}
          />
        </div>
        <DateFilterDropdown
          value={filterObject?.operatorValue === 'is' ? filterObject.value : ''}
          rangeValue={filterObject?.operatorValue === 'range' ? filterObject.value : { from: '', to: '' }}
          open={openDateFilter}
          anchorEl={dateFilterAnchorEl}
          setAnchorEl={setDateFilterAnchorEl}
          menuHeading={columnName}
          onCancel={() => setDateFilterAnchorEl(null)}
          onSubmit={(values: any) => advancedFilters(values)}
          field={field}
          openOnRight={openOnRight}
          showOnlyContains={showOnlyContains}
        />
      </div>
    );
  }

  if (type === 'select') {
    return (
      <div className={classes.searchBoxWrapper}>
        <div className={classes.searchBox} onClick={() => handleSelectClick()}>
          <TextField
            ref={selectDropdownRef}
            className={`${classes.textField} ${advancedFiltering ? classes.disabledTextField : ''}`}
            onClick={() => handleSelectClick()}
            disabled={advancedFiltering}
            placeholder="Select"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src="https://dsjvxb1plg419.cloudfront.net/v2.0/arrow-down-icon.svg"
                    alt="arrow-down-icon"
                    height="12px"
                    width="12px"
                  />
                </InputAdornment>
              )
            }}
            variant="outlined"
            sx={{ input: { color: getColor('border', 200) } }}
            fullWidth
          />
        </div>
        <CheckboxDropdown
          open={openSelect}
          checkBoxData={checkBoxData as any}
          value={filterObject?.value}
          Heading={columnName}
          field={field}
          onChange={(data: any) => setCheckBoxData(data)}
          isFilterDropdown
          isButtonsEnabled
          anchorElm={selectAnchorEl}
          setAnchorElm={setSelectAnchorEl}
          selectedValues={(values: any) => advancedFilters(values)}
          openOnRight={openOnRight}
        />
      </div>
    );
  }

  return (
    <div className={classes.searchBoxWrapper}>
      <div onClick={() => handleSearchClick()} className={classes.searchBox}>
        <TextField
          ref={searchTextFieldRef}
          className={`${classes.textField} ${
            (advanceNumberFiltering && type === 'number') || advancedFiltering ? classes.disabledTextField : ''
          }`}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onClick={() => handleSearchClick()}
          // disabled={(advanceNumberFiltering && type === 'number') || advancedFiltering}
          type={type}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src="https://dsjvxb1plg419.cloudfront.net/v2.0/search-icon.svg"
                  alt="icon"
                  className="searchIcon"
                  height="11px"
                  width="11px"
                />
              </InputAdornment>
            )
          }}
          variant="outlined"
          sx={{ input: { color: getColor('border', 200) } }}
          fullWidth
        />
      </div>
      {type === 'number' ? (
        <NumberFilterDropdown
          open={openTextFilter}
          value={filterObject?.value}
          anchorEl={textFilterAnchorEl}
          setAnchorEl={setTextFilterAnchorEl}
          field={field}
          menuHeading={columnName}
          onCancel={() => setTextFilterAnchorEl(null)}
          onSubmit={(values: any) => advancedFilters(values)}
          openOnRight={openOnRight}
        />
      ) : (
        <TextFilterDropdown
          open={openTextFilter}
          value={filterObject?.value}
          anchorEl={textFilterAnchorEl}
          setAnchorEl={setTextFilterAnchorEl}
          field={field}
          menuHeading={columnName}
          mobileNumber={type === 'mobile'}
          showOnlyContains={showOnlyContains}
          onCancel={() => setTextFilterAnchorEl(null)}
          onSubmit={(values: any) => advancedFilters(values)}
          openOnRight={openOnRight}
        />
      )}
    </div>
  );
};

const operator = (type: string) => {
  if (type === 'string' || type === '') {
    return 'contains';
  } else if (type === 'number') {
    return '=';
  } else if (type === 'date') {
    return 'is';
  } else if (type === 'boolean') {
    return 'is';
  } else if (type === 'select') {
    return 'select';
  } else {
    return '';
  }
};

export type TeComplexTableProps = {
  /**
   * data to be displayed in table rows
   */
  rows?: any[];
  /**
   * table headers
   */
  columns?: any[];
  /**
   * height of each row
   */
  rowHeight?: number;
  /**
   * height of header row
   */
  headerHeight?: number;
  /**
   * custom padding for the header
   */
  headerPadding?: string;
  /**
   * classNames of the defined classes for custom CSS
   */
  classNames?: string;
  /**
   * width of the table
   */
  width?: string | number;
  /**
   * width of the table
   */
  minWidth?: string | number;
  /**
   * height of the table
   */
  height?: string;
  /**
   * minimum height of the table
   */
  minHeight?: number;
  /**
   * background color
   */
  background?: string;
  /**
   * additional styles to be added for the table
   */
  customStyles?: object;
  /**
   * array of column field that are pinned to left side of the table
   */
  leftPinnedColumns?: string[];
  /**
   * array of column field that are pinned to right side of the table
   */
  rightPinnedColumns?: string[];
  /**
   * makes the table height dynamic
   */
  enableAutoHeight?: boolean;
  /**
   * hide footer of the table
   */
  hideFooter?: boolean;
  /**
   * if true, displays the active filters panel above the table
   */
  enableActiveFiltersPanel?: boolean;
  /**
   * if true enables the advance filtering
   */
  enableAdvancedFiltering?: boolean;
  /**
   * if true enables the advance filtering for number field
   */
  enableAdvanceNumberFiltering?: boolean;
  /**
   * Filter immediately without pressing enter key
   */
  filterImmediately?: boolean;
  /**
   * Filtering can be processed on the server or client-side. Set it to 'server' if you would like to handle filtering on the server-side.
   */
  filterMode?: 'server' | 'client';
  /**
   * Currently applied filters
   */
  activeFilters?: GridFilterItem[];
  /**
   * returns the data of onChange of search filter
   */
  onFilterChange?: Function;
  /**
   * tableHeaderData is Table Header Content
   */
  customTableHeaderData?: any;
  /**
   * to enable checkbox in every row
   */
  checkboxSelection?: boolean;
  /**
   * to enable row click for selection in every row
   */
  disableSelectionOnClick?: boolean;
  isRowSelectable?: any;

  /**
   * Array of selected rows
   */
  selectionModel?: any[];
  /**
   * callback function to recieve the selected items in array
   */
  onselectionChange?: Function;
  /**
   * this data of the row will be added to the array
   */
  getDataOnSelectionChange?: string;
  /**
   * get custom row heigth eg: () => 'auto'
   */
  getRowHeight?: any;
  /**
   * add custom mui classes
   */
  muiClasses?: any;
  /**
   * add custom row spacing
   */
  getRowSpacing?: any;
  /**
   * add custom row class
   */
  getRowClassName?: any;
  /**
   * add custom no result overlay header
   */
  customNoResultsHeader?: any;
  /**
   * add custom no result overlay message
   */
  customNoResultsMessage?: any;
  /**
   * add custom height for no results overlay header and message
   */
  customNoResultsOverlayHeight?: number;
  /**
   * add custom no rows(data) overlay header
   */
  customNoRowsHeader?: any;
  /**
   * add custom no rows(data) overlay message
   */
  customNoRowsMessage?: any;
  /**
   * add custom height for no rows(data) overlay header and message
   */
  customNoRowsOverlayHeight?: number;
  /**
   * set this to true to show no results found on applied filters
   */
  isUnFilteredDataPresent?: boolean;
  /**
   * date formatter function
   */
  dateFormatter?: (date: any) => string;
  /**
   * callback function triggered when updating a cell
   */
  processRowUpdate?: (newRow: GridRowModel, oldRow?: GridRowModel) => GridRowModel;
  /**
   * conditionally enable/disable a cell for editing of an editable column
   */
  isCellEditable?: (params: GridCellParams) => boolean;
  /**
   * set loading of data for the table
   */
  isLoading?: boolean;
  /**
   * enable/disable pagination for the table
   */
  enablePagination?: boolean;
  /**
   * set pagination mode to client/server
   */
  paginationMode?: 'client' | 'server';
  /**
   * set number of rows to display in one page
   */
  pageSize?: number | undefined;
  /**
   * set total number of rows to display
   */
  totalRowCount?: number | undefined;
  /**
   * callback function when page is changed in pagination
   */
  handlePageChange?: Function;
  /**
   * current page number while using
   */
  page?: any;
  /**
   * enable sorting of 1st column by default
   */
  enableDefaultSort?: boolean;
  /**
   * column to use for default sort
   */
  defaultSortColumn?: { field: string; sort: 'asc' | 'desc' } | null;
};

export function LvComplexTable({
  rows = [],
  columns = [],
  rowHeight = 45,
  headerHeight = 40,
  width = '100%',
  minWidth = 'auto',
  isLoading = false,
  height,
  minHeight = 0,
  customStyles = {},
  background = getColor('shades', 0),
  classNames = '',
  leftPinnedColumns = [],
  rightPinnedColumns = [],
  enableAutoHeight = true,
  hideFooter = true,
  enableAdvancedFiltering = false,
  enableAdvanceNumberFiltering = false,
  filterImmediately = true,
  filterMode = 'client',
  activeFilters = [],
  onFilterChange = () => {},
  customTableHeaderData,
  checkboxSelection = false,
  disableSelectionOnClick = true,
  isRowSelectable,
  selectionModel,
  onselectionChange = () => {},
  getDataOnSelectionChange = '',
  headerPadding = '',
  getRowHeight = () => {},
  muiClasses = {},
  getRowSpacing = () => {
    return {
      top: 0,
      bottom: 0
    };
  },
  getRowClassName = () => {},
  customNoResultsHeader = 'No record found as per filter criteria',
  customNoResultsMessage = '',
  customNoResultsOverlayHeight = 200,
  customNoRowsHeader = 'Please Add Data',
  customNoRowsMessage = 'You will see your data here after adding it.',
  customNoRowsOverlayHeight = 200,
  isUnFilteredDataPresent = false,
  dateFormatter,
  enableActiveFiltersPanel,
  processRowUpdate,
  isCellEditable = () => true,
  handlePageChange = () => {},
  enablePagination = false,
  paginationMode = 'server',
  pageSize = 10,
  page = 1,
  totalRowCount = undefined,
  enableDefaultSort = true,
  defaultSortColumn = null
}: TeComplexTableProps) {
  const filters: CustomGridFilterItem[] = columns
    .filter((column) => column.isSearchable)
    .map((column, index) => {
      let filterObj = activeFilters?.find((filter: any) => filter.columnField === column.field);
      return (
        filterObj ?? {
          id: index,
          columnField: column.field,
          operatorValue: operator(column?.type ?? 'string'),
          value: '',
          filterDisplayName: column.filterDisplayName
        }
      );
    });
  const classes = useStyles();

  let defaultValues = {};
  let customHeaderHeight = 0;

  columns.some((column: any) => !!column.isSearchable)
    ? (customHeaderHeight = headerHeight + 68)
    : (customHeaderHeight = headerHeight);

  const tableHeaderData = columns.map((data) => {
    const headerAlign: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent' = data.headerAlign;
    return {
      ...data,
      ...(data.type === 'date'
        ? { filterOperators: dateFilterOperators }
        : data.type === 'select'
        ? { filterOperators: selectFilterOperators }
        : {}),
      renderHeader: () => (
        <div onKeyDown={(e) => e.stopPropagation()}>
          <Box
            className={classes.headerBox}
            style={{
              height: `${headerHeight}px`,
              lineHeight: '19.5px',
              letterSpacing: '1.2px',
              textAlign: headerAlign,
              whiteSpace: data?.minWidth ? 'normal' : 'nowrap',
              padding: headerPadding ? headerPadding : '10px 12px'
            }}
          >
            {data.headerName}
          </Box>
          <div className={classes.searchContainer} onClick={(e) => e.stopPropagation()}>
            {data.isSearchable && (
              <SearchBar
                onChange={(e: any) => {
                  if (data.type === 'date') {
                    let formattedDate = '';
                    if (e !== null) {
                      formattedDate = moment(e).format('YYYY-MM-DD');
                    }
                    handleSearch(formattedDate, data.field);

                    return;
                  }
                  if (!filterImmediately) return;
                  handleSearch(e.target.value, data.field);
                }}
                onKeyDown={(e: any) => {
                  if (!filterImmediately && e.key === 'Enter' && data.type !== 'date') {
                    handleSearch(e.target.value, data.field);
                  }
                }}
                type={data.type}
                field={data?.field}
                columnName={data?.name}
                openOnRight={data?.openOnRight}
                showOnlyContains={data?.showOnlyContains}
                selectData={data?.values}
                advancedFiltering={enableAdvancedFiltering}
                advanceNumberFiltering={enableAdvanceNumberFiltering}
                advancedFilters={(values: any) => advancedSearch(values)}
                filterObject={filters.find((filter: any) => filter.columnField === data.field)}
              />
            )}
          </div>
        </div>
      )
    };
  });

  const formatRowData = (rows: any[]) => {
    let cleanData: any[];
    cleanData = rows.map((row: any, index): any => {
      row['id'] = row.id ?? index;
      return Object.assign({}, defaultValues, row);
    });
    return cleanData;
  };

  const handleSearch = (value: any, field: string) => {
    if (!enableAdvancedFiltering) {
      const filtersCopy = [...filters];
      filtersCopy.forEach((element: any) => {
        if (element.columnField === field) {
          element.value = value;
        }
      });
      onFilterChange(filtersCopy);
    }
  };

  const advancedSearch = (data: any) => {
    if (enableAdvancedFiltering || enableAdvanceNumberFiltering) {
      const filtersCopy = [...filters];
      filtersCopy.forEach((element: any) => {
        if (element.columnField === data.columnField) {
          element.operatorValue = data.operator;
          element.value = data.value;
          if (data.fullObjectValues) element.fullObjectValues = data.fullObjectValues;
        }
      });
      onFilterChange(filtersCopy);
    }
  };

  const handleSelectionChange = (selection: any) => {
    let result = selection.map((id: any) => {
      return getDataOnSelectionChange
        ? rows.find((row) => row.id === id)[getDataOnSelectionChange]
        : rows.find((row) => row.id === id);
    });
    onselectionChange(result);
  };

  const paginationLoadingOverlay = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
        height: 'inherit',
        background: 'white',
        position: 'relative'
      }}
    >
      <CircularProgress size={45} />
      <Typography variant="body2" p={1}>
        Loading ...
      </Typography>
    </Box>
  );

  const defaultNoResultOverlay = (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%" p={1}>
      <img src="https://dsjvxb1plg419.cloudfront.net/v2.0/add-data.svg" alt="no-data" height={40} />
      <Typography variant="body2">{customNoResultsHeader}</Typography>
      {customNoResultsMessage && <Typography variant="caption">{customNoResultsMessage}</Typography>}
    </Box>
  );

  const defaultNoRowsOverlay = (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%" p={1}>
      <img src="https://dsjvxb1plg419.cloudfront.net/v2.0/add-data.svg" alt="no-data" height={40} />
      <Typography variant="body2">{customNoRowsHeader}</Typography>
      <Typography variant="caption">{customNoRowsMessage}</Typography>
    </Box>
  );

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const currentPage = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        sx={{
          '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
              '&:hover': {
                background: getColor('extra', 300),
                opacity: 0.7
              },
              background: getColor('extra', 300),
              color: 'white'
            }
          }
        }}
        shape="rounded"
        count={pageCount}
        page={currentPage + 1} // pagination number starts from 1
        onChange={(event, value) => {
          apiRef.current.setPage(value - 1);
        }}
      />
    );
  };

  const CustomFooter = () => {
    return (
      <div style={{ padding: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div>
          <CustomPagination />
        </div>
      </div>
    );
  };

  // Allow editing editable cells on single click
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({} as GridCellModesModel);

  const handleCellClick = useCallback((params: any, event: any) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit }
        }
      } as GridCellModesModel;
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <Grid container>
      {enableActiveFiltersPanel && (
        <ActiveFiltersPanel
          activeFilters={filters}
          dateFormatter={dateFormatter}
          onFilterChange={(newFilters) => onFilterChange(newFilters)}
        />
      )}
      <div
        style={{ width, minWidth, height, minHeight, background }}
        className={`${classNames ? ' ' + classNames : ''}`}
      >
        <DataGridPro
          rows={formatRowData(rows)}
          columns={customTableHeaderData ?? tableHeaderData}
          checkboxSelection={checkboxSelection}
          isRowSelectable={isRowSelectable}
          selectionModel={selectionModel}
          disableSelectionOnClick={disableSelectionOnClick}
          onSelectionModelChange={(selection: any) => handleSelectionChange(selection)}
          classes={{
            cellCheckbox: classes.cellCheckbox,
            columnHeaderCheckbox: classes.columnHeaderCheckbox,
            ...muiClasses
          }}
          getRowClassName={getRowClassName}
          sx={{
            '&.MuiDataGrid-root': {
              borderRadius: '8px',
              border: `1px solid ${getColor('border', 0)}`,
              '& .MuiDataGrid-iconButtonContainer': {
                position: 'absolute',
                top: '0',
                right: '0',
                padding: '6px',
                background: getColor('border', 0)
              },
              '& .MuiDataGrid-columnHeader': {
                background: getColor('border', 0),
                minHeight: '40px',
                padding: 0
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'block',
                height: `${customHeaderHeight}px`,
                overflowY: 'hidden',
                whiteSpace: 'normal'
              },
              '& .MuiDataGrid-columnSeparator': {
                minHeight: 'auto !important',
                height: `${headerHeight}px`,
                top: 0
              },

              '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                display: 'block',
                height: customTableHeaderData ? '100%' : 'auto'
              },
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none !important'
              },

              '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                color: getColor('border', 0)
              },

              '& .MuiDataGrid-cell': {
                fontSize: '13px',
                fontWeight: '400',
                padding: '0px 12px',
                lineHeight: '20px',
                color: getColor('border', 200),
                fontFamily: 'Work Sans'
              },

              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none'
              },

              '& .right-border-class': {
                borderRight: `1px solid ${getColor('neutral', 400)}`
              },

              '& .MuiDataGrid-iconSeparator': {
                color: customTableHeaderData ? 'transparent' : 'inherit'
              }
            },
            ...customStyles
          }}
          rowHeight={
            rows.length ? rowHeight : isUnFilteredDataPresent ? customNoResultsOverlayHeight : customNoRowsOverlayHeight
          }
          getRowHeight={getRowHeight}
          getRowSpacing={getRowSpacing}
          initialState={{
            pinnedColumns: { left: leftPinnedColumns, right: rightPinnedColumns },
            sorting: {
              sortModel: enableDefaultSort
                ? [{ field: defaultSortColumn?.field ?? columns[0].field, sort: defaultSortColumn?.sort ?? 'asc' }]
                : []
            }
          }}
          filterModel={{
            items: filterMode === 'client' ? filters : [],
            linkOperator: GridLinkOperator.And
          }}
          disableColumnMenu
          hideFooterRowCount
          hideFooter={enablePagination ? false : hideFooter}
          pagination={enablePagination}
          paginationMode={paginationMode}
          page={page - 1} // datagrid number starts from 0
          pageSize={pageSize}
          loading={isLoading}
          onPageChange={(page: any, details: any) => {
            handlePageChange(page, details);
          }}
          rowCount={totalRowCount}
          autoHeight={enableAutoHeight}
          headerHeight={customHeaderHeight}
          localeText={{
            footerRowSelected: CustomPagination
          }}
          components={{
            Footer: CustomFooter,
            LoadingOverlay: paginationLoadingOverlay,
            NoRowsOverlay: () => {
              return isUnFilteredDataPresent ? defaultNoResultOverlay : defaultNoRowsOverlay;
            }
          }}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          isCellEditable={isCellEditable}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
        />
      </div>
    </Grid>
  );
}
