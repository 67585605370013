export const breadcrumbData = {
  breadcrumbSeparator: false,
  stepKey: 1,
  steps: [
    {
      title: 'DASHBOARD - SETTINGS',
      titleDisplay: true
    }
  ],
  pageHeader: {
    header: 'Settings',
    navigationButton: false,
    helpText: 'Settings'
  }
};
