import { toast } from 'react-toastify';
import { AuthContextProps } from '../../../contexts/authContext';
import DashboardService from '../service/DashboardService';

export default class DashboardManager {
  private userId: string;

  constructor(authContext: AuthContextProps) {
    this.userId = authContext?.authState?.id as string;
  }

  async getDashboardDetail() {
    try {
      const response: any = await DashboardService.getDashboardDetail(this.userId);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }
}
