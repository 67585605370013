import requestManager from '../../../api/requestManager';

const getAllDeals = (syndicateId: string) =>
  requestManager.apiGet(`/v1/syndicates/syndicate_startups/?id=${syndicateId}`);

const getDealDetail = (syndicateId: string, dealId?: string) =>
  requestManager.apiGet(`/v1/syndicates/syndicate_current_deal_startup_info/?id=${syndicateId}&startup_id=${dealId}`);

const getBackersForDeal = (syndicateId: string, syn_deal_id?: string) =>
  requestManager.apiGet(
    `/v1/syndicates/syndicate_current_deal_info_section_combined/?id=${syndicateId}&syn_deal_id=${syn_deal_id}`
  );

const getUninvitedBackersForDeal = (syndicateId: string, startupId?: string) =>
  requestManager.apiGet(
    `/v1/syndicates/uninvited_backers_to_syndicate_deal/?id=${syndicateId}&startup_id=${startupId}`
  );

const inviteBackersForDeal = (syndicateId: string, data: any) =>
  requestManager.apiPost(`/v1/syndicates/invite_selected_syndicate_deal_backer/?id=${syndicateId}`, data);

const updateSchemeData = (syndicateId: string, data: any, savingInDraftMode: boolean) =>
  requestManager.apiPost(
    `/v1/syndicates/diy_update_startup_specific_scheme_data/?id=${syndicateId}&draft_mode=${savingInDraftMode}`,
    data,
    {
      'Content-Type': 'multipart/form-data'
    }
  );

const submitForApproval = (syndicateId: string, data: any) =>
  requestManager.apiPost(`/v1/syndicates/diy_scheme_doc_generation/?id=${syndicateId}`, data);

const fetchStartupFormData = (syndicateId: string, startupId?: String) =>
  requestManager.apiGet(`/v1/syndicates/fetch_startup_data/?startup_id=${startupId}&syndicate_id=${syndicateId}`);

const fetchSchemeInfoFormData = (syndicateId: string, startupId?: String) =>
  requestManager.apiGet(`/v1/syndicates/diy_fetch_scheme_data/?id=${syndicateId}&startup_id=${startupId}`);

export const getLookupData = (search: string, type: string, user_search_private_hashtag?: string) => {
  const finalURL = `/v1/admin/lookups/get_lookup_data/?type=${type}&q=${search}${
    user_search_private_hashtag ? `&user_search_private_hashtag=${user_search_private_hashtag}` : ''
  }`;
  return requestManager.apiGet(finalURL);
};

export const getSectors = (sectorType: any = 'vertical_sector') => {
  const finalURL = `/public/lookups/?type=${sectorType}`;
  return requestManager.apiGet(finalURL);
};

const createOrUpdateStartup = (savingInDraftMode: Boolean = false, syndicateId: string, data: any) =>
  requestManager.apiPost(
    `/v1/syndicates/create_or_update_startup/?syndicate_id=${syndicateId}&data_source=glassboard&draft_mode=${savingInDraftMode}`,
    data,
    {
      'Content-Type': 'multipart/form-data'
    }
  );

const launchSyndicateDeal = (syndicatePermalinkdata: any, data: any) =>
  requestManager.apiPost(`/v1/syndicates/launch_syndicate_deal/?permalink=${syndicatePermalinkdata}`, data);

const manageCommitment = (permalink: string, startup_id: string, data: any) =>
  requestManager.apiPost(
    `/v1/syndicates/syndicate_deal_commitment_record/?permalink=${permalink}&startup_id=${startup_id}`,
    data
  );

const sendDrawdown = (data: any) => requestManager.apiPost(`/v1/investor_drawdown/sent_drawdown_to_investor/`, data);

export default {
  getAllDeals,
  getDealDetail,
  getBackersForDeal,
  inviteBackersForDeal,
  getUninvitedBackersForDeal,
  updateSchemeData,
  submitForApproval,
  fetchStartupFormData,
  fetchSchemeInfoFormData,
  getLookupData,
  getSectors,
  createOrUpdateStartup,
  launchSyndicateDeal,
  manageCommitment,
  sendDrawdown
};
