import React from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getColor } from '../../colors';
import { LVThemeProvider } from '../theme/lvtheme';

export type LinkButtonProps = {
  /**
   * title text for button .
   */
  title?: string;
  /**
   *function definition for on click routing.
   */
  onClick?: () => void;
  /**
   * prop to hide the button
   */
  hidden?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles({
  root: {
    color: getColor('accent', 0),
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  rootDisabled: {
    color: getColor('neutral', 300),
    cursor: 'not-allowed',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
});
export function LinkButton({ title = 'view all', onClick, hidden = false, disabled = false }: LinkButtonProps) {
  const classes = useStyles();
  const handleClick = (e: any) => {
    e.preventDefault();
    onClick && onClick();
  };
  if (hidden) {
    return <div />;
  }
  return (
    <div>
      <LVThemeProvider>
        <a className={disabled ? classes.rootDisabled : classes.root} onClick={!disabled ? handleClick : () => {}}>
          <Typography variant="heading_06_medium" color={disabled ? getColor('neutral', 300) : getColor('accent', 0)}>
            {title}
          </Typography>
        </a>
      </LVThemeProvider>
    </div>
  );
}
