import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DIY_LOGIN_SCREEN_IMAGE, SYNICATE_LOGO_COLOUR } from '../../../../common/Images';
import { Info } from 'lucide-react';
import { Box, Button, Grid, Typography, IconButton, Link } from '@mui/material';

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Box className="min-h-screen" display="flex">
      {/* Left Side Image Section */}
      <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="center" p={2} bgcolor="#ffffff">
        <img src={DIY_LOGIN_SCREEN_IMAGE} alt="Investment Growth Illustration" style={{ maxWidth: '100%' }} />
      </Grid>

      {/* Right Side Login Section */}
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bgcolor="#ffffff"
        p={4}
      >
        <Box maxWidth="md" width="100%" display="flex" flexDirection="column" alignItems="center">
          {/* Logo and Heading Section */}
          <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
            <img src={SYNICATE_LOGO_COLOUR} alt="Letsventure" style={{ height: '120px' }} />
          </Box>

          {/* Login Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => loginWithRedirect()}
            sx={{
              width: '50%',
              py: 1,
              '&:hover': { backgroundColor: 'blue.600' },
              transition: 'background-color 0.2s'
            }}
          >
            Login
          </Button>

          {/* Note Section */}
          <Box mt={2} display="flex" alignItems="center" color="text.secondary">
            <IconButton size="small" sx={{ mr: 1 }}>
              <Info fontSize="small" />
            </IconButton>
            <Typography variant="body2">
              For any login-related concerns, please email us at{' '}
              <Link href="https://mail.google.com/mail/?view=cm&fs=1&to=contactus@letsventure.com" color="primary">
                contactus@letsventure.com
              </Link>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default Login;
