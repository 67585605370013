import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import UserImage from '../../../assests/image/user.png';
import { getSyndicateId, getUser } from '../../../common/utils/common';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { getColor } from '../../../ui-library/colors';
import { OutlinedButton } from '../../../ui-library/components/buttons/outlined-button';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import './leadinvestor.css';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(() => ({
  lead_carry_input: {
    border: '1px solid #d1d1d1',
    fontSize: 14,
    '&:hover': {
      borderColor: 'red'
    },
    '&:focus': {
      borderColor: 'red'
    },
    '&:active': {
      borderColor: 'red'
    }
  }
}));

export default function LeadInvestorList(props) {
  const styles = useStyles();
  const {
    user,
    isMandatory,
    carryError,
    labelTitle,
    investorList,
    notes,
    innerText,
    saveLeadInvestor,
    // addInvestors,
    removeInvestor,
    index = null,
    setFieldValue,
    removeIcon,
    errors,
    labelName,
    values,
    textinfo,
    validateField,
    isZeno = false
  } = props;

  const syndicateId = getSyndicateId();
  const [leadInvestor, setLeadInvestor] = useState(null);
  const [leadCarry, setLeadCarry] = useState(null);
  const [openForm, setOpenForm] = useState(null);
  const [onChangeError, setOnChangeError] = useState(false);
  const totalCarry = investorList.reduce((sum, item) => sum + Number(item.lead_carry), 0.0);
  const leadCarryAllocationLeft = values.total_lead_carry ? values.total_lead_carry - totalCarry : 0;

  const loadInvestorList = async (input, _callback) => {
    if (input.trim() === '') {
      return Promise.resolve({ options: [] });
    }
    const response = await fetch(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/lookups.json?type=all_angel_investors&custom=true&is_lead=true&q=${input}&syndicate_id=${syndicateId}&current_user_id=${
        getUser().user_id
      }`
    );
    const json = await response.json();
    const newJson = json.map((field, k) => ({
      label: field.text,
      options: field.children
        .filter((item) => item.new_entry !== true)
        .map((child, k1) => ({
          value: child.id,
          label: child.text,
          role: child.role,
          location: child.location,
          profile_image: child.profile_image,
          logo_img: child.logo_img,
          new_entry: child.new_entry,
          type: child.type
        }))
    }));
    return newJson;
  };

  const addLeadInvestor = () => {
    const leadInvestorObj = { label: leadInvestor.label, value: leadInvestor.value, lead_carry: leadCarry };
    // investorList.push(leadInvestorObj);
    values.selected_lead_investor = leadInvestorObj;
    setOpenForm(false);
    saveLeadInvestor(setFieldValue, values);
  };

  return (
    <div className="edit-form-row">
      {labelTitle && (
        <label className="edit__profile_label-title">
          {labelTitle} {isMandatory ? <span className="text-danger">*</span> : ''}
        </label>
      )}

      <Grid container spacing={2}>
        {investorList?.length
          ? investorList.map((item, key) => (
              <Grid item xs={4} key={key}>
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  border="1px solid #d1d1d1"
                  borderRadius="4px"
                  p="4px"
                >
                  <div className="p-1">
                    <img
                      src={item.profile_image || UserImage}
                      height="30px"
                      width="30px"
                      className="rounded-circle"
                      alt=""
                    />
                  </div>

                  <div>
                    {!!item.label && (
                      <Typography variant="paragraph_03_medium">{`${item.label.slice(0, 15)}...`}</Typography>
                    )}
                    {!!item.lead_carry && <Typography variant="paragraph_03_bold">{`${item.lead_carry}%`}</Typography>}
                  </div>
                  {!isZeno && !!removeIcon && (
                    <div
                      className="ms-auto"
                      style={{ cursor: 'pointer' }}
                      onClick={() => removeInvestor(item.value, setFieldValue, investorList)}
                    >
                      <DeleteOutlinedIcon />
                    </div>
                  )}
                </Stack>
              </Grid>
            ))
          : null}
      </Grid>
      <Grid container item xs={3} mt={1}>
        {!isZeno && (user === 'founder' ? investorList.length === 0 : true) && (
          <OutlinedButton onClick={() => setOpenForm(true)} customStyles={{ whiteSpace: 'nowrap' }}>
            <AddIcon style={{ marginRight: '10px' }} />
            {innerText}
          </OutlinedButton>
        )}
      </Grid>
      {!!textinfo && (
        <Typography variant="caption_regular" my="10px" textAlign="left">
          <span style={{ color: getColor('neutral', 600) }}>{textinfo}</span>
        </Typography>
      )}

      {!!openForm && (
        <Stack direction="row" gap={2}>
          <Box>
            <AsyncSelect
              styles={{
                control: (base) => ({
                  ...base,
                  height: '40px',
                  minHeight: '40px',
                  width: '330px',
                  fontFamily: 'Work Sans',
                  color: '#040607',
                  fontSize: '14px',
                  borderRadius: '4px',
                  border: '1px solid #d1d1d1',
                  ':hover': {
                    borderColor: getColor('primary', 700)
                  },
                  ':focus': {
                    borderColor: getColor('primary', 700)
                  },
                  ':active': {
                    borderColor: getColor('primary', 700)
                  },
                  boxShadow: 'none'
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                  return {
                    ...styles,
                    fontFamily: 'Work Sans',
                    zIndex: 9999
                  };
                },
                menu: (styles) => {
                  return {
                    ...styles,
                    zIndex: 9999,
                    fontFamily: 'Work Sans'
                  };
                }
              }}
              loadOptions={loadInvestorList}
              placeholder="Enter the name of your investor"
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? 'Please Enter 1 or more character' : 'No results found'
              }
              onChange={(investor) => {
                setLeadInvestor(investor);
              }}
            />
          </Box>

          <Box flex={1}>
            <input
              type="number"
              min="0"
              max={leadCarryAllocationLeft}
              name="carry"
              style={{
                width: '100%',
                padding: '8px 14px',
                fontFamily: 'Work Sans',
                color: '#040607',
                fontSize: '14px',
                borderRadius: '4px',
                boxShadow: 'none'
              }}
              className="lead_carry_input"
              onChange={(carry) => {
                let carryValue = carry.target.value;
                if (carry.target.value < 0) carryValue = 0;
                if (carry.target.value > leadCarryAllocationLeft) {
                  carryValue = leadCarryAllocationLeft;
                }
                if (carry.target.value > leadCarryAllocationLeft) {
                  carryValue = leadCarryAllocationLeft;
                  setOnChangeError(true);
                } else {
                  setOnChangeError(false);
                }
                carry.target.value = carryValue;
                setLeadCarry(carryValue);
              }}
              placeholder="Enter lead carry"
            />

            {onChangeError ? (
              <Typography variant="subtitle2" color={getColor('error', 800)}>
                Lead carry allocation should be less than or equal to {leadCarryAllocationLeft}%
              </Typography>
            ) : values.total_lead_carry > 0.5 ? (
              <Typography variant="subtitle2">
                {`Lead carry allocation left is ${leadCarryAllocationLeft}%`}{' '}
              </Typography>
            ) : (
              ''
            )}
          </Box>

          <ActionButton
            onClick={() => {
              addLeadInvestor();
              setTimeout(() => {
                validateField('lead_investor_list');
              }, 1000);
            }}
          >
            Save
          </ActionButton>
        </Stack>
      )}

      {(isMandatory || carryError) && errors && errors[labelName] && (
        <Typography variant="subtitle1" color={getColor('error', 800)}>
          {errors[labelName]}
        </Typography>
      )}
      {notes && <div className="edit_profile_notes"> {notes} </div>}
    </div>
  );
}
