import { Box } from '@mui/material';
import React, { FC } from 'react';
import { CardContainer } from '../../../ui-library/components/card/card-container';

const SettingCard: FC<any> = ({ syndicateDetail = {}, primaryAdmin = {} }) => {
  const { syndicate_name = null, formated_joined_lv_date = null, cc_email = null, access = false } = syndicateDetail;
  const { name = null } = primaryAdmin;
  const cardOptions = [
    {
      label: 'Syndicate Name',
      spacing: 3,
      value: syndicate_name ?? '-'
    },
    {
      label: 'Joined on LetsVenture',
      spacing: 3,
      value: formated_joined_lv_date ?? '-'
    },
    {
      label: 'Primary Admin',
      spacing: 3,
      value: name ?? '-'
    },
    {
      label: 'cc emails',
      spacing: 3,
      value: cc_email ?? '-'
    },
    {
      label: 'Allow backers to access public Deals',
      spacing: 4,
      value: access ? 'Yes' : 'No',
      helpText: 'If yes, all new users will get access to public deals on the platform by default'
    }
  ];

  return (
    <Box className="card_border text-left" mb={3}>
      <CardContainer options={cardOptions} />
    </Box>
  );
};

export default SettingCard;
