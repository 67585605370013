import { ActionOptionType } from '../../backers/constants/IndividualBackerConstants';

export enum ActionOptions {
  VIEW_STARTUP_DETAILS = 'View Startup Details',
  SHARE_DEAL_URL = 'Share Deal URL',
  CLOSE_DEAL = 'Close Deal',
  CANCEL_DEAL = 'Cancel Deal'
}
export const ActionOptionsList: ActionOptionType[] = [
  {
    label: ActionOptions.VIEW_STARTUP_DETAILS
  },
  {
    label: ActionOptions.SHARE_DEAL_URL
  }
];

export enum BackersForDealStatus {
  INVITED = 'invited',
  VIEWED = 'viewed',
  COMMITED = 'commited',
  TRANSFERRED = 'transferred'
}

export const TYPE_OF_COMMUNICATION_KEY: any = {
  [BackersForDealStatus.INVITED]: 'not_viewed',
  [BackersForDealStatus.VIEWED]: 'not_committed',
  [BackersForDealStatus.COMMITED]: 'not_transfered'
};

export enum DealTabType {
  STARTUP_INFO = 'startup_info',
  SCHEME_INFO = 'scheme_info'
}

export const DEAL_FORM_TAB_OPTIONS: any = [
  {
    name: 'STARTUP INFO',
    value: DealTabType.STARTUP_INFO,
    content: ''
  },
  {
    name: 'SCHEME INFO',
    value: DealTabType.SCHEME_INFO,
    content: ''
  }
];

export const ManageCommitOptions = [
  {
    label: 'Investor should commit this minimum amount',
    value: '0'
  },
  {
    label: 'Investor should commit this exact amount',
    value: '1'
  },
  {
    label: 'Investor should be shown this exact amount but can be more also',
    value: '2'
  }
];
