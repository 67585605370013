import React, { FC, useState } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { Alert, Avatar, Box, Stack, Typography } from '@mui/material';
import { getCurrencySymbol } from '../../../common/utils/common';
import { rupeeFormatter } from '../../../PreCommitSyndicateProfile/zenoCorpus';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { OutlinedButton } from '../../../ui-library/components/buttons/outlined-button';
import { FormContainer } from '../../../ui-library/components/form-elements/FormContainer';

const SendDrawdownModal: FC<any> = ({ openModal, handleClose, handleSubmit, apiLoading }) => {
  const [transferByDate, setTransferByDate] = useState<any>(null);

  const getAmount = (type: any, currentValue: any) => {
    let result = '';
    if (type === 'Money Received') {
      result =
        currentValue?.transferred_amount && currentValue?.transferred_amount_currency
          ? getCurrencySymbol(currentValue?.transferred_amount_currency.name) + ' '
          : '';
      result +=
        rupeeFormatter.format(currentValue?.transferred_amount) +
        ' of ' +
        getCurrencySymbol(currentValue?.transferred_amount_currency.name) +
        ' ';
      result += rupeeFormatter.format(currentValue?.actual_commitment_amount);
    } else {
      result = `${
        currentValue?.committed_amount && currentValue?.committed_amount_currency
          ? getCurrencySymbol(currentValue?.committed_amount_currency.name)
          : ''
      } ${currentValue?.committed_amount ? rupeeFormatter.format(currentValue?.committed_amount) : ''}`;
    }
    return result;
  };
  const handleTransferDateChange = (value: any, context: any) => {
    const formattedDate = moment(value).format('YYYY-MM-DD') + 'T10:30:00.000Z';
    setTransferByDate(new Date(formattedDate));
  };

  return (
    <CommonModal title="Send Drawdown" isOpen={!!openModal} handleClose={handleClose} hideButtons>
      <FormContainer>
        {openModal.inv_type == 'Foreign' ? (
          <>
            <Typography variant="paragraph_02_medium">
              Please contact the LetsVenture team at <span className="fw-bold">aif@letsventure.com</span> to send the
              drawdown for this investor as they transfer their funds through their foreign bank account. LetsVenture
              team checks the currency exchange rates and sends the drawdown accordingly.
            </Typography>
            <br />
            <Stack direction="row" justifyContent="center" mt={2}>
              <ActionButton onClick={handleClose}>OK</ActionButton>
            </Stack>
          </>
        ) : (
          <Box>
            <Box className="d-flex">
              <Avatar
                style={{
                  width: '45px',
                  height: '45px'
                }}
                src={openModal.img}
                className="investor_list_img"
              />
              <Box className="ms-3 my-auto">
                <Typography variant="sub_heading_bold">{openModal.name}</Typography>
                <Box className="d-flex mt-2">
                  {!!openModal.committed_amount && (
                    <Typography variant="paragraph_02_medium">
                      Amount:{'  '}
                      <span className="fw-bold">{getAmount(null, openModal)}</span>
                    </Typography>
                  )}
                  {!!openModal.date && (
                    <Typography variant="paragraph_02_medium" ml={2}>
                      Committed date: <span className="fw-bold">{openModal.date}</span>
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box style={{ marginTop: 20 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  className="my-3"
                  label="Transfer By*"
                  onChange={handleTransferDateChange}
                  minDate={moment().add(6, 'days')}
                  maxDate={moment().add(15, 'days')}
                />
              </LocalizationProvider>
              <Typography variant="subtitle2">
                Select the date by which the investor has to transfer the drawdown funds. Dates between 7 and 15 days
                from today can be chosen.
              </Typography>
            </Box>
            <Alert sx={{ mt: '30px' }} severity="info">
              You can send the drawdowns only for Indian, NRE and NRO transfer types. For Foreign transfer type, please
              contact LetsVenture team to send the drawdowns.
            </Alert>
            <br />

            <Stack direction="row" gap={1} justifyContent="end" marginTop={2}>
              <OutlinedButton onClick={handleClose}>Cancel</OutlinedButton>
              <ActionButton
                disabled={!transferByDate || apiLoading}
                isLoading={apiLoading}
                onClick={() => handleSubmit(transferByDate)}
              >
                Continue
              </ActionButton>
            </Stack>
          </Box>
        )}
      </FormContainer>
    </CommonModal>
  );
};

export default SendDrawdownModal;
